import React from 'react'

const header = (props) => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
        <a className="navbar-brand" href="/">
          <img src="/logo32.png" alt="stopwatch-timer.net" className="logo" />Stopwatch-Timer</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/clock" title="Online Clock">Clock</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/" title="Online Stopwatch">Stopwatch</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/countdown-timer" title="Online Countdown Timer" >Countdown Timer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pomodoro-timer" title="Online Pomodoro Timer" >Pomodoro Timer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/bomb-timer" >Bomb Timer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/rocket-timer" >Rocket Timer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/cat-timer" >Cat Timer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/dino-game" >Dino Game</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/tic-tac-toe" >Tic Tac Toe</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/date-calculator" >Date Calculator</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/blog" >Blog</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default header