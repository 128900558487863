import React from 'react'
// import { Switch, Route, Redirect } from 'react-router'
import { Switch, Route } from 'react-router'
import { LANGUAGES } from '../_i18n/languages';
import loadable from '@loadable/component'

// import About from '../about/About'
const Terms = loadable(() => import('../terms/Terms'))
const Privacy = loadable(() => import('../privacy/Privacy'))
const NotFound = loadable(() => import('../notFound/NotFound'))
const Stopwatch = loadable(() => import('../stopwatch/Stopwatch'))
const Timer = loadable(() => import('../timer/Timer'))
const PomodoroTimer = loadable(() => import('../pomodoroTimer/PomodoroTimer'))
const BombTimer = loadable(() => import('../bombTimer/BombTimer'))
const RocketTimer = loadable(() => import('../rocketTimer/RocketTimer'))
const DateCalculator = loadable(() => import('../dateCalculator/DateCalculator'))
const Clock = loadable(() => import('../clock/Clock'))
const Dino = loadable(() => import('../games/dino/Dino'))
const TicTacToe = loadable(() => import('../games/ticTacToe/TicTacToe'))
const CatTimer = loadable(() => import('../catTimer/CatTimer'))

const MultiLanguageRoute = (props) => {
    //const defaultLanguage = LANGUAGES.en.urlLang
    let hasLang = props.computedMatch.params.lang
    hasLang = hasLang ? hasLang : 'en'
    let pathParam1 = props.location.pathname.split('/')[1]
    // let pathParam2 = props.location.pathname.split('/')[2]
    let pathParam3 = props.location.pathname.split('/')[3]
    // const is404Page = props.location.pathname.split('/')[3]
    const is404Page = (pathParam3 && pathParam1 !== 'countdown-timer') ? true : pathParam3 && pathParam3.indexOf('timer') === -1
    //const isBasePathWithoutLang = props.location.pathname === "/"
    const currentLanguage = LANGUAGES[hasLang]

    //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
    //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
    if (!currentLanguage || is404Page) return <Route component={NotFound} />

    // return <Route {...props} />
    return <Route path={props.path} component={() => <props.component {...props} />} />
}

const router = (props) => (
    <React.Fragment>
        <Switch>
            <Route exact path='/' component={Stopwatch} />
            {/* <Route path='/about' component={About} /> */}
            <Route path='/terms' component={Terms} />
            <Route path='/privacy' component={Privacy} />
            {/* <Route path='/timers' render={(props) => <Home {...props} custom={{
                'title': '',
                'description': ''
            }} />} /> */}
            <MultiLanguageRoute path='/pomodoro-timer/:lang?' component={PomodoroTimer} />
            <MultiLanguageRoute path='/countdown-timer/:lang?' component={Timer} />
            <MultiLanguageRoute path='/online-timer/:lang?' component={Timer} custom={{
                'title': 'timer.online-timer.seo.title',
                'description': 'timer.online-timer.seo.description',
                'h1': 'timer.online-timer.h1',
                'desc1': 'timer.online-timer.desc.1'
            }} />
            <MultiLanguageRoute path='/online-countdown/:lang?' component={Timer} custom={{
                'title': 'timer.online-countdown.seo.title',
                'description': 'timer.online-countdown.seo.description',
                'h1': 'timer.online-countdown.h1',
                'desc1': 'timer.online-countdown.desc.1'
            }} />
            <MultiLanguageRoute path='/classroom-online-timer/:lang?' component={Timer} custom={{
                'title': 'timer.classroom-online-timer.seo.title',
                'description': 'timer.classroom-online-timer.seo.description',
                'h1': 'timer.classroom-online-timer.h1',
                'desc1': 'timer.classroom-online-timer.desc.1'
            }} />
            <MultiLanguageRoute path='/timer-for-kids/:lang?' component={Timer} custom={{
                'title': 'timer.timer-for-kids.seo.title',
                'description': 'timer.timer-for-kids.seo.description',
                'h1': 'timer.timer-for-kids.h1',
                'desc1': 'timer.timer-for-kids.desc.1'
            }} />
            <MultiLanguageRoute path='/tomato-timer/:lang?' component={PomodoroTimer} custom={{
                'title': 'pomodoroTimer.tomato-timer.seo.title',
                'description': 'pomodoroTimer.tomato-timer.seo.description',
                'h1': 'pomodoroTimer.tomato-timer.h1',
                'desc1': 'pomodoroTimer.tomato-timer.desc.1'
            }} />            
            <MultiLanguageRoute path='/bomb-timer/:lang?' component={BombTimer} />
            <MultiLanguageRoute path='/rocket-timer/:lang?' component={RocketTimer} />
            <MultiLanguageRoute path='/date-calculator/:lang?' component={DateCalculator} />
            <MultiLanguageRoute path='/clock/:lang?' component={Clock} />
            <MultiLanguageRoute path='/dino-game/:lang?' component={Dino} />
            <MultiLanguageRoute path='/tic-tac-toe/:lang?' component={TicTacToe} />
            <MultiLanguageRoute path='/cat-timer/:lang?' component={CatTimer} />
            <MultiLanguageRoute path='/:lang' component={Stopwatch} />
            <Route component={NotFound} />
        </Switch>
    </React.Fragment>
)


export default router