import React
// , { useState, useEffect } 
from 'react'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import AdSense from 'react-adsense';
// import loadGAds from '../common/loadGAds';

import Header from '../common/template/header';
import Footer from '../common/template/footer';
import Routes from './routes';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC9rv5hDB6g767KK5rJuXoXOXcLZOHG53c",
    authDomain: "stopwatch-timer-9fdaa.firebaseapp.com",
    projectId: "stopwatch-timer-9fdaa",
    storageBucket: "stopwatch-timer-9fdaa.appspot.com",
    messagingSenderId: "656061812217",
    appId: "1:656061812217:web:d895c115d54e5eeb6e8128",
    measurementId: "G-E7SJF6R36Y"
};

const App = (props) => {

    // const [loaded, setLoaded] = useState(false);
    // useEffect(() => {
    //     loadGAds(() => {
    //         setLoaded(true);
    //     });
    // });
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    if (app.name && typeof window !== 'undefined') {
        getAnalytics(app);
    }

    return (
        <React.Fragment>
            <div>
                <Header />
                <div className="container mt-main">
                    <AdSense.Google
                        client='ca-pub-2605486701851317'
                        slot='4112021007'
                        style={{ display: 'block' }}
                        format='auto'
                        responsive='true'
                        layoutKey='-gw-1+2a-9x+5c'
                    />
                    {/* // auto full width responsive ads */
                        //loaded ? : ''
                    }
                    <Routes />
                </div>
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default App